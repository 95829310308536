/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900');

/*@import './app-theme';*/
//@import '~material-design-icons/iconfont/material-icons.css';

@import "../node_modules/ag-grid-enterprise/styles/ag-grid.css";
@import "../node_modules/ag-grid-enterprise/styles/ag-theme-material.css";
//@import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    //--color-green-600: 22 163 74;
    --color-green-600: #005154;
  }
  html {
    font-family: "Inter", system-ui, sans-serif;
  }
}

//$theme: mat.m2-define-light-theme((
//  typography: (
//    brand-family: 'Inter',
//    bold-weight: 600
//  ),
//  density: (
//    scale: -1
//  )
//));

body, html, app-root {
  height: 100%;
  margin: 0;
}


@layer components {
  .label {
    @apply font-[450] text-[15px] mb-2;
  }
}


* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

#main-container {
  //height: calc(100vh - 65px);
  //overflow-y: auto;
  margin-top: 48px;
}

//:focus-visible {
//  outline: none !important;
//}


@import "../node_modules/@angular/material/prebuilt-themes/cyan-orange.css";

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

formly-wrapper-mat-form-field {
  .mat-mdc-notch-piece {
    border: none;
  }
}


.custom-select-panel {
  background: white !important;

  .mat-mdc-option {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}


.mat-mdc-option.mdc-list-item {
  background: rgb(255 255 255) !important;
}

html {
  --mat-autocomplete-background-color: white !important;
}
